import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import WordBox from './WordBox';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import WordDialog from './WordDialog';

const categories = [
    {
        id: 1,
        name: "A1"
    },
    {
        id: 2,
        name: "A2"
    },
    {
        id: 3,
        name: "B1"
    },
    {
        id: 4,
        name: "B2"
    },
    {
        id: 5,
        name: "C1"
    },
    {
        id: 6,
        name: "C2"
    },
    {
        id: 7,
        name: "Academic"
    },
    {
        id: 15,
        name: "Idioms"
    },
    {
        id: 14,
        name: "Phrasal Verbs"
    }
]

function WordsPage() {
    const [searchKey, setSearchKey] = useState("");
    const [categoryId, setCategoryId] = useState(-1);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState(-1);
    const [words, setWords] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetWord, setTargetWord] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [wordToRemove, setWordToRemove] = useState(null);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

    const fetchWords = () => {
        axios.get(`${process.env.REACT_APP_API_URL}word/getFilteredWords?searchKey=${searchKey}&categoryId=${categoryId}&subCategoryId=${subCategoryId}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                if (startIndex === 0) {
                    setWords([]);
                }
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.words && response.data.words.length === 50);
                    if (startIndex === 0) {
                        setWords(response.data.words);
                    } else {
                        setWords((prev) => [...prev, ...response.data.words]);
                    }
                }
            });
    }

    useEffect(() => {
        setSubCategoryId(-1);
        if (categoryId === -1) {
            setSubCategories([]);
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}word/getOnlySubCategories?id=${categoryId}`)
                .catch((err) => {
                    console.log("err: " + err);
                    setSubCategories([]);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        console.log(response.data.subcategories);
                        setSubCategories(response.data.subcategories);
                    }
                });
        }
    }, [categoryId])

    useEffect(() => {
        fetchWords();
    }, [categoryId, subCategoryId, startIndex]);

    const handleFormSubmit = async (values) => {
        if (targetWord) {
            if (targetWord.translation) {
                //edit word and translation
                await axios.patch(`${process.env.REACT_APP_API_URL}word/updateWord?wordId=${targetWord.id}&language=${targetWord.language}`, {
                    word: {
                        english_word: values.english_word,
                        category: values.category,
                        subcategory: values.subcategory,
                        ...(targetWord.language === "turkish" ? { turkish_word: values.translation } : {})
                    },
                    oldDefinition: {
                        content: targetWord.definition
                    },
                    definition: {
                        content: values.definition
                    },
                    examples: [
                        {
                            sentence: values.example,
                            highlight: values.example_highlight
                        },
                        {
                            sentence: values.example_translation,
                            highlight: values.example_translation_highlight
                        }
                    ],
                    translation: {
                        content: values.translation
                    }
                }).catch((err) => {
                    console.log("err: " + err);
                }).then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        handleClose();
                        if (startIndex === 0) {
                            fetchWords();
                        } else {
                            setStartIndex(0);
                        }
                    }
                });
            } else {
                //add new language to the word
                await axios.patch(`${process.env.REACT_APP_API_URL}word/updateWordAndAddTranslation?wordId=${targetWord.id}&language=${targetWord.language}`, {
                    word: {
                        english_word: values.english_word
                    },
                    examples: [
                        {
                            sentence: values.example,
                            highlight: values.example_highlight
                        },
                        {
                            sentence: values.example_translation,
                            highlight: values.example_translation_highlight,
                            lang: targetWord.language
                        }
                    ],
                    translation: {
                        content: values.translation
                    }
                }).catch((err) => {
                    console.log("err: " + err);
                }).then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        handleClose();
                        if (startIndex === 0) {
                            fetchWords();
                        } else {
                            setStartIndex(0);
                        }
                    }
                });
            }
        } else {
            //add new word
            await axios.post(`${process.env.REACT_APP_API_URL}word/addWord`, {
                english_word: values.english_word,
                turkish_word: values.translation,
                category: values.category,
                subCategory: values.subcategory,
                definition: values.definition,
                examples: [
                    {
                        sentence: values.example,
                        highlight: values.example_highlight,
                        lang: "english"
                    },
                    {
                        sentence: values.example_translation,
                        highlight: values.example_translation_highlight,
                        lang: "turkish"
                    }
                ],
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    if (startIndex === 0) {
                        fetchWords();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (targetWord) {
            setDialogOpen(true);
        }
    }, [targetWord]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetWord(null);
    }


    const updateWord = (w) => {
        setTargetWord(w);
    }

    useEffect(() => {
        if (wordToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [wordToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setWordToRemove(null);
    }

    const handleWordToRemove = (w) => {
        if (w && (w.language === "turkish" || w.translation)) {
            setWordToRemove(w);
        }
    }

    const removeWord = async () => {
        if (wordToRemove.language === "turkish") {
            await axios.delete(`${process.env.REACT_APP_API_URL}word/deleteWord?id=${wordToRemove.id}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (startIndex === 0) {
                        fetchWords();
                    } else {
                        setStartIndex(0);
                    }
                    handleRemoveDialogClose();
                }
            });
        } else {
            await axios.delete(`${process.env.REACT_APP_API_URL}word/deleteWordTranslation?id=${wordToRemove.id}&language=${wordToRemove.language}`).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (startIndex === 0) {
                        fetchWords();
                    } else {
                        setStartIndex(0);
                    }
                    handleRemoveDialogClose();
                }
            });
        }

    }

    const handleCategoryDialogClose = () => {
        setCategoryDialogOpen(false);
    }

    const onCategorySelected = (categoryId) => {
        window.open(`${process.env.REACT_APP_WEB_URL}word-categories/${categoryId}`, '_self');
    }

    return (
        <Box sx={{ p: "75px" }}>
            <WordDialog targetWord={targetWord} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />
            <Dialog onClose={handleCategoryDialogClose} open={categoryDialogOpen}>
                <DialogTitle><Typography variant='h3'>Select Category</Typography></DialogTitle>
                <List sx={{ pt: 0 }}>
                    {categories.map((ct) => (
                        <ListItem key={ct.id} disableGutters>
                            <ListItemButton onClick={() => onCategorySelected(ct.id)} key={ct.id + "ct"}>
                                <ListItemText primary={ct.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{wordToRemove?.language === "turkish" ? "Are you sure you want to delete the word?" : "Are you sure you want to delete the word's translation?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {wordToRemove?.language === "turkish" ?
                            "Word and all related data (translations etc.) will be deleted. You cannot undo this operation, you can add the same word again if you wish." :
                            "Word translation and all related data (example etc.) will be deleted. You cannot undo this operation, you can add the same translation again if you wish."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeWord()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Words" subtitle="You can see and manage words from this page." />


                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setDialogOpen(true);
                }} >
                    Add New Word
                </Button>

                <Button variant='contained' color='warning' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setCategoryDialogOpen(true);
                }} >
                    Categories
                </Button>

            </Box>
            <Box display="flex" alignItems="center" gap="20px">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Search</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={() => {
                                        setWords([]);
                                        if (startIndex === 0) {
                                            fetchWords();
                                        } else {
                                            setStartIndex(0);
                                        }
                                    }}
                                    //onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="category">Category</InputLabel>
                    <Select
                        id="category"
                        value={categoryId}
                        label="Category"
                        onChange={(e) => {
                            setStartIndex(0);
                            setWords([]);
                            setCategoryId(e.target.value);
                        }}
                    >
                        <MenuItem value={-1}>All</MenuItem>
                        <MenuItem value={1}>A1</MenuItem>
                        <MenuItem value={2}>A2</MenuItem>
                        <MenuItem value={3}>B1</MenuItem>
                        <MenuItem value={4}>B2</MenuItem>
                        <MenuItem value={5}>C1</MenuItem>
                        <MenuItem value={6}>C2</MenuItem>
                        <MenuItem value={7}>Academic</MenuItem>
                        <MenuItem value={14}>Phrasal Verbs</MenuItem>
                        <MenuItem value={15}>Idioms</MenuItem>
                    </Select>
                </FormControl>

                {subCategories && categoryId !== -1 &&
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="subcategory">Subcategory</InputLabel>
                        <Select
                            id="subcategory"
                            value={subCategoryId}
                            label="Subcategory"
                            onChange={(e) => {
                                setStartIndex(0);
                                setWords([]);
                                setSubCategoryId(e.target.value);
                            }}
                        >
                            <MenuItem value={-1}>All</MenuItem>
                            {subCategories.map((sc) =>
                                <MenuItem key={sc.id} value={sc.id}>{sc.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={words.length}
                    next={() => {
                        setStartIndex(words.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all words.</b>
                        </p>
                    }
                >
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        {words.map(w => <WordBox key={w.id + "-word"} word={w} updateWord={updateWord} removeWord={handleWordToRemove} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default WordsPage
