import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import WordSetBox from './WordSetBox';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';

function WordSetsPage() {
    const [searchKey, setSearchKey] = useState("");
    const [wordSets, setWordSets] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [wordSetToRemove, setWordSetToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const fetchWordSets = () => {
        axios.get(`${process.env.REACT_APP_API_URL}wordset/getWordSets?searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                if (startIndex === 0) {
                    setWordSets([]);
                }
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.wordSets && response.data.wordSets.length === 50);
                    if (startIndex === 0) {
                        setWordSets(response.data.wordSets);
                    } else {
                        setWordSets((prev) => [...prev, ...response.data.wordSets]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchWordSets();
    }, [startIndex]);

    const handleRemoveDialogClose = async () => {
        setWordSetToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeWordSet = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}wordset/deleteWordSet?wordSetId=${wordSetToRemove.id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchWordSets();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (wordSetToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [wordSetToRemove])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the word set?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new word set if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeWordSet()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">

                <Header title="Word Sets" subtitle="You can see and manage word sets from this page." />

                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Search</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={() => {
                                        setWordSets([]);
                                        if (startIndex === 0) {
                                            fetchWordSets();
                                        } else {
                                            setStartIndex(0);
                                        }
                                    }}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={wordSets.length}
                    next={() => {
                        setStartIndex(wordSets.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all word sets.</b>
                        </p>
                    }
                >
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        {wordSets.map(ws => <WordSetBox key={ws.id} wordSet={ws} removeWordSet={(wordSet) => setWordSetToRemove(wordSet)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default WordSetsPage
